import React, { useEffect } from "react";
import moment from "moment";
import thLocale from "moment/locale/th";
import { useRoutes } from "react-router-dom";
import VConsole from "vconsole";
import Routes from "./routes";
import useAuth from "./hooks/useAuth";
import { CreateCustomTheme } from "./theme";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import useSettings from "./hooks/useSettings";
import SplashScreen from "./components/splashScreen/SplashScreen";
import { authStatus } from "./contexts/authContext/AuthContext";
import NavBar from "./components/navigation/NavBar";
import { DealsProvider } from "./contexts/dealsContext";
import { LoadingScreenProvider } from "./contexts/LoadingScreenContext";
import ProgressScreen from "./components/progressScreen/ProgressScreen";
import useTranslation from "./hooks/useTranslation";
import { debugFlag } from "./config";
import { Container, useMediaQuery } from "@mui/material";
import { DropdownLanguageProvider } from "./contexts/DropdownLanguageContext";

const App = () => {
  const { initializeTranslation } = useTranslation();
  const content = useRoutes(Routes());
  const { settings } = useSettings();
  const auth = useAuth();
  const cardSelected = React.useRef(false);
  const merchantSelected = React.useRef(false);
  const merchantScrollLeft = React.useRef(0);
  const [open, setOpen] = React.useState(false);
  const utheme = useTheme();
  const matchSM = useMediaQuery(utheme.breakpoints.only("xs"));
  const [activeDropdownLanguage, setActiveDropdownLanguage] =
    React.useState(false);

  const theme = CreateCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  useEffect(() => {
    initializeTranslation();
    const lang = localStorage.getItem("lang");
    if (lang === "th") {
      moment.updateLocale("th", [thLocale]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (debugFlag.enableDebugTool) {
    new VConsole({ theme: "dark" });
  }

  return (
    <ThemeProvider theme={theme}>
      <LoadingScreenProvider
        value={{
          setOpen,
        }}
      >
        <DealsProvider
          value={{
            merchantSelected,
            cardSelected,
            merchantScrollLeft,
          }}
        >
          <CssBaseline />
          <ProgressScreen open={open} />
          {auth.status === authStatus.INITIAL ? (
            <SplashScreen />
          ) : (
            <>
              <DropdownLanguageProvider value={setActiveDropdownLanguage}>
                <NavBar activeDropdownLanguage={activeDropdownLanguage} />
                <Container disableGutters={matchSM}>{content}</Container>
              </DropdownLanguageProvider>
            </>
          )}
        </DealsProvider>
      </LoadingScreenProvider>
    </ThemeProvider>
  );
};

export default App;
