import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import useTranslation from "../../../../hooks/useTranslation";
import EmptyMyCollection from "../../components/nftMyCollection/emptyMyCollection/EmptyMyCollection";
import MyCollection from "../../components/nftMyCollection/myCollection/MyCollection";
import Prompt from "../../../../components/Prompt/Prompt";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAsyncNftMyCollection,
  status as NftMyCollectionStatus,
} from "../../redux/slices/nftMyCollection/NftMyCollection";

const NftMyCollection = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "walletVoucherTab",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { collections, status: collectionsStatus } = useSelector(
    state => state.nftMyCollection,
  );
  const { status: tokensStatus } = useSelector(state => state.nftMyToken);

  const [promptOpen, setPromptOpen] = useState(false);
  const [promptError, setPromptError] = useState({
    title: null,
    subtitle: null,
    buttonText: null,
    onClose: null,
  });

  useEffect(() => {
    if (
      collectionsStatus === NftMyCollectionStatus.ERROR ||
      tokensStatus === NftMyCollectionStatus.ERROR
    ) {
      setPromptError({
        title: "Unable to proceed",
        subtitle: "Please try again.",
        buttonText: "Back to Wallet",
        onClose: () => navigate("/wallet"),
      });
      setPromptOpen(true);
    } else {
      setPromptOpen(false);
    }
  }, [collectionsStatus, tokensStatus, navigate]);

  useEffect(() => {
    dispatch(fetchAsyncNftMyCollection());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>{t("My NFT Collection")}</title>
      </Helmet>
      {!collections?.length &&
      collectionsStatus === NftMyCollectionStatus.LOADED ? (
        <Box sx={{ mt: "56px", mb: "32px", mx: "16px" }}>
          <EmptyMyCollection />
        </Box>
      ) : (
        <Box sx={{ mt: "36px" }}>
          <MyCollection />
        </Box>
      )}
      <Prompt
        isVisible={promptOpen}
        icon={"error"}
        onClose={promptError.onClose}
        title={promptError.title}
        subTitle={promptError.subtitle}
        buttonText={promptError.buttonText}
      />
    </>
  );
};

export default NftMyCollection;
