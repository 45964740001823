import React, { useState } from "react";
import { AppBar, IconButton, MenuItem, Select, Toolbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ReactComponent as NavbarABCLogo } from "../../assets/icons/navbar_abc_logo.svg";
import useTranslation from "../../hooks/useTranslation";

const NavBar = ({ activeDropdownLanguage }) => {
  const navigate = useNavigate();
  const [lang, setLang] = useState(localStorage.getItem("lang") || "th");
  const { initializeTranslation } = useTranslation();
  const handleTitleClick = () => {
    navigate("/");
  };

  const handleLanguageChange = event => {
    const selectedLang = event.target.value;
    setLang(selectedLang);
    navigate({
      pathname: "/onboarding",
      search: `?lang=${selectedLang}`,
    });
    initializeTranslation();
  };

  return (
    <AppBar position="sticky" sx={{ backgroundColor: "background.paper" }}>
      <Toolbar
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          justifyContent: "space-between",
        }}
      >
        <div />
        <IconButton
          id="navbar-home-btn"
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2, display: "grid", padding: 0, margin: 0 }}
          onClick={handleTitleClick}
        >
          <NavbarABCLogo />
        </IconButton>

        {activeDropdownLanguage && (
          <Select
            data-testid="navbar-select"
            value={lang}
            onChange={handleLanguageChange}
            variant="standard"
            disableUnderline
            sx={{ width: "max-content", marginLeft: "auto" }}
          >
            <MenuItem data-testid="navbar-th-munuitem" value={"th"}>
              ไทย
            </MenuItem>
            <MenuItem data-testid="navbar-en-munuitem" value={"en"}>
              English
            </MenuItem>
          </Select>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
