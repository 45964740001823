import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  AppBar,
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "../../redux/store/configureStore";
import {
  fetchAsyncConsent,
  resetConsent,
  setVisitedConsentPage,
  status as ConsentStatus,
} from "../../redux/slices/consent/Consent";
import {
  fetchAsyncSaveConsent,
  resetSaveConsent,
  status as SaveConsentStatus,
} from "../../redux/slices/consent/SaveConsent";
import useTranslation from "../../hooks/useTranslation";
import TermsAndConditionsSkeleton from "../../components/skeletonLoading/TermsAndConditionsSkeleton";
import Prompt from "../../components/Prompt/Prompt";
import LoadingPrompt from "../../components/loadingPrompt/LoadingPrompt";
import useNavigationHook from "../../hooks/useNavigation";
import { CollectionPoint } from "../../constants/consent";
import {
  trackSavePDPAConsent,
  trackSkipPDPAConsent,
} from "../../mixpanel/consent";

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  "&.Mui-checked": {
    color: theme.palette.secondary.green.accent1,
  },
}));

const Consent = () => {
  const locationState = useLocation()?.state;
  const collectionPoint =
    locationState?.from === CollectionPoint.PRIVACY_SETTING
      ? CollectionPoint.PRIVACY_SETTING
      : CollectionPoint.OPEN_APP;
  const setActiveMenu = useNavigationHook();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { consents, status: consentStatus } = useSelector(
    state => state.consent,
  );
  const { status: saveConsentStatus } = useSelector(state => state.saveConsent);
  const { t } = useTranslation("translation", {
    keyPrefix: "consent",
  });

  const [checked, setChecked] = useState({});
  const [openCollapse, setOpenCollapse] = useState({});
  const saveChecked = useRef({});

  const [promptOpen, setPromptOpen] = useState(false);
  const [prompt, setPrompt] = useState({
    icon: null,
    title: null,
    subtitle: null,
    buttonText: null,
    onClose: null,
  });

  const [promptLoadingOpen, setPromptLoadingOpen] = useState(false);

  useEffect(() => {
    if (collectionPoint === CollectionPoint.PRIVACY_SETTING) {
      setActiveMenu(false);
    }
  }, [setActiveMenu, collectionPoint]);

  useEffect(() => {
    if (consentStatus === ConsentStatus.INITIAL && !consents?.length) {
      if (collectionPoint === CollectionPoint.OPEN_APP) {
        dispatch(setVisitedConsentPage());
      }
      dispatch(fetchAsyncConsent(collectionPoint));
    }
  }, [dispatch, collectionPoint, consents, consentStatus]);

  useEffect(() => {
    return () => {
      dispatch(resetConsent());
      dispatch(resetSaveConsent());
    };
  }, [dispatch]);

  useEffect(() => {
    if (consentStatus === ConsentStatus.LOADED) {
      if (!consents?.length) {
        navigate("/", { replace: true });
        return;
      }
      let newChecked = {};
      let newCollapse = {};
      consents.forEach(item => {
        if (item.status === "active") {
          newChecked[item.consentName] = true;
        } else {
          newChecked[item.consentName] = false;
        }
        newCollapse[item.consentName] = false;
      });
      setChecked(newChecked);
      setOpenCollapse(newCollapse);
    }
  }, [consentStatus, consents, navigate]);

  useEffect(() => {
    if (
      consentStatus === ConsentStatus.ERROR &&
      collectionPoint === CollectionPoint.PRIVACY_SETTING
    ) {
      setPrompt({
        keyPrefix: "Prompt",
        icon: "error",
        title: "Unable to proceed",
        subtitle: "Please try again.",
        buttonText: "Back",
        buttonSx: {},
        onClose: () => {
          setPromptOpen(false);
          navigate(-1);
        },
      });
      setPromptOpen(true);
    }
  }, [consentStatus, collectionPoint, navigate]);

  useEffect(() => {
    if (saveConsentStatus === SaveConsentStatus.LOADED) {
      const listActiveConsent = Object.keys(saveChecked.current).filter(
        key => saveChecked.current[key],
      );
      trackSavePDPAConsent(collectionPoint, listActiveConsent);
      setPromptLoadingOpen(false);
      setPrompt({
        keyPrefix: "consent",
        icon: "success",
        title: "Consent Saved",
        subtitle:
          "You have the right to change your consent by yourself at the Privacy Setting menu.",
        buttonText: "OK",
        buttonSx: {
          backgroundColor: "success.main",
        },
        onClose: () => {
          setPromptOpen(false);
          if (collectionPoint === CollectionPoint.PRIVACY_SETTING) {
            navigate("/settings");
          } else {
            navigate("/", { replace: true });
          }
        },
      });
      setPromptOpen(true);
    } else if (saveConsentStatus === SaveConsentStatus.ERROR) {
      setPromptLoadingOpen(false);
      setPrompt({
        keyPrefix: "consent",
        icon: "error",
        title: "Failed to Save Consent",
        subtitle: "Please try again later.",
        buttonText: "OK",
        buttonSx: {},
        onClose: () => {
          setPromptOpen(false);
        },
      });
      setPromptOpen(true);
    }
  }, [saveConsentStatus, collectionPoint, navigate]);

  const handleOnClickPrivacyNotice = () => {
    navigate("/settings/privacy-policy");
  };

  const getStatus = isCheck => {
    if (isCheck) {
      return "active";
    }
    if (collectionPoint === CollectionPoint.PRIVACY_SETTING) {
      return "withdraw";
    }
    return "no-consent";
  };

  const handleOnClickSave = () => {
    setPromptLoadingOpen(true);
    const consentList = consents.map(item => ({
      consentName: item.consentName,
      application: item.application,
      version: item.version,
      status: getStatus(checked[item.consentName]),
    }));
    saveChecked.current = checked;
    dispatch(fetchAsyncSaveConsent({ consentList }));
  };

  const handleOnClickSkip = () => {
    if (collectionPoint === CollectionPoint.PRIVACY_SETTING) {
      navigate(-1);
    } else {
      trackSkipPDPAConsent(CollectionPoint.OPEN_APP);
      navigate("/", { replace: true });
    }
  };

  const handleOnOpenCollapse = id => {
    setOpenCollapse(op => {
      return {
        ...op,
        [id]: !op[id],
      };
    });
  };

  const handleChangeCheckAll = event => {
    setChecked(s => {
      let updatedCheck = { ...s };
      Object.keys(s).forEach(key => {
        updatedCheck[key] = event.target.checked;
      });
      return updatedCheck;
    });
  };

  const handleChangeCheck = (event, id) => {
    setChecked(s => ({
      ...s,
      [id]: event.target.checked,
    }));
  };

  const getCheckedAll = () => {
    return !Object.keys(checked).find(key => !checked[key]);
  };

  const getIsDisabledSave = () => {
    if (collectionPoint === CollectionPoint.PRIVACY_SETTING) {
      return false;
    }
    return !Object.keys(checked).find(key => checked[key]);
  };

  const ConsentListItem = ({ title, content, id }) => {
    return (
      <>
        <Box sx={{ mb: "24px" }}>
          <Box
            sx={{
              mb: "16px",
              display: "flex",
              justifyContent: "space-between",
            }}
            onClick={() => handleOnOpenCollapse(id)}
          >
            <Typography variant="h6">{title}</Typography>
            <Box sx={{ display: "flex", ml: "10px" }}>
              {openCollapse?.[id] ? (
                <ExpandMore sx={{ fontSize: "1.75rem" }} />
              ) : (
                <ExpandLess sx={{ fontSize: "1.75rem" }} />
              )}
            </Box>
          </Box>
          <Box>
            <Collapse
              in={openCollapse ? openCollapse[id] : false}
              timeout="auto"
              unmountOnExit
            >
              <Typography variant="body1">{content}</Typography>
            </Collapse>
          </Box>
          <Box>
            <FormControlLabel
              control={
                <CustomCheckbox
                  checked={checked[id]}
                  onChange={event => handleChangeCheck(event, id)}
                />
              }
              label={t("I consent")}
            />
          </Box>
        </Box>
      </>
    );
  };

  const ConsentDetails = () => {
    return (
      <>
        <Box sx={{ mb: "33px" }}>
          <Box sx={{ mb: "16px" }}>
            <Typography variant="h5">
              {t("ABC’s Personal Data Consent Form")}
            </Typography>
          </Box>
          <Box sx={{ mb: "23px" }}>
            <Typography variant="body1">
              {t("consent-part-1")}
              <Typography
                component="span"
                sx={{ color: "secondary.blue.accent5" }}
                onClick={handleOnClickPrivacyNotice}
              >
                {t("Privacy Notice")}
              </Typography>
              {t("consent-part-2")}
            </Typography>
          </Box>
          <Box sx={{ mb: "7px" }}>
            <FormControlLabel
              control={
                <CustomCheckbox
                  checked={getCheckedAll()}
                  onChange={handleChangeCheckAll}
                />
              }
              label={t("I consent all")}
            />
          </Box>
          <Box sx={{ mb: "16px" }}>
            <Typography variant="body1">
              {t(
                "I have thoroughly read and understood the following consents",
              )}
            </Typography>
          </Box>
        </Box>
        <Box>
          {consents.map(item => (
            <ConsentListItem
              key={item.consentName}
              id={item.consentName}
              title={item.title}
              content={item.content}
            />
          ))}
        </Box>
      </>
    );
  };

  const renderSkipButtonText = () => {
    return collectionPoint === CollectionPoint.PRIVACY_SETTING
      ? t("BACK")
      : t("SKIP");
  };

  const renderSaveButtonText = () => {
    return collectionPoint === CollectionPoint.PRIVACY_SETTING
      ? t("SAVE-PRIVACY-SETTING")
      : t("SAVE");
  };

  return (
    <>
      <Helmet>
        <title>
          {collectionPoint === CollectionPoint.PRIVACY_SETTING
            ? t("Privacy Settings")
            : t("Consent")}
        </title>
      </Helmet>
      {consentStatus === ConsentStatus.LOADING ? (
        <Box
          sx={{ backgroundColor: "background.paper", padding: "0 16px" }}
          data-testid="terms-skeleton-container"
        >
          <TermsAndConditionsSkeleton />
        </Box>
      ) : (
        !!consents && (
          <Box sx={{ mt: "32px" }}>
            <Box sx={{ px: "16px", mb: "122px" }}>
              <ConsentDetails />
            </Box>
            <AppBar
              position="fixed"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                boxShadow: 2,
                top: "auto",
                bottom: 0,
                p: "16px 16px 32px",
                backgroundColor: "background.paper",
                flexDirection: "row",
              }}
            >
              <Button
                color="primary"
                variant="outlined"
                sx={{
                  borderRadius: 16,
                  width: "47%",
                  height: "50px",
                }}
                onClick={handleOnClickSkip}
              >
                <Typography variant="button">
                  {renderSkipButtonText()}
                </Typography>
              </Button>
              <Button
                color="primary"
                variant="contained"
                sx={{
                  borderRadius: 16,
                  width: "47%",
                  height: "50px",
                }}
                disabled={getIsDisabledSave()}
                onClick={handleOnClickSave}
              >
                <Typography variant="button">
                  {renderSaveButtonText()}
                </Typography>
              </Button>
            </AppBar>
          </Box>
        )
      )}
      <LoadingPrompt
        isVisible={promptLoadingOpen}
        title={"Processing"}
        keyPrefix={"consent"}
      />
      <Prompt
        isVisible={promptOpen}
        icon={prompt.icon}
        onClose={prompt.onClose}
        title={prompt.title}
        subTitle={prompt.subtitle}
        buttonText={prompt.buttonText}
        acceptButtonSx={prompt.buttonSx}
        keyPrefix={prompt.keyPrefix}
      />
    </>
  );
};

export default Consent;
