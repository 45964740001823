import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import useTranslation from "../../hooks/useTranslation";
import { AppBar, Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { authStatus } from "../../contexts/authContext/AuthContext";
import { useKeycloak } from "@react-keycloak/web";
import Cindy from "../../assets/images/OnBoarding/cindy.png";
import { ReactComponent as ABCwalletLogo } from "../../assets/images/OnBoarding/Logo_ABC Wallet-02.svg";
import useDropDownLanguageHook from "../../hooks/useDropdownLanguageHook";

const Onboarding = () => {
  const { t } = useTranslation("translation", { keyPrefix: "onboarding" });
  const setActiveDropdownLanguage = useDropDownLanguageHook();
  const navigate = useNavigate();
  const { status, initialize } = useAuth();
  const { keycloak } = useKeycloak();

  useEffect(() => {
    setActiveDropdownLanguage(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSignup = () => {
    const lang = localStorage.getItem("lang") || "th";
    if (status === authStatus.GUEST) {
      keycloak?.login({ locale: lang });
      return;
    }

    if (status === authStatus.ERROR) {
      initialize();
      navigate("/");
      return;
    }
    navigate("/terms-and-conditions");
  };

  return (
    <>
      <Helmet>
        <title>{t("ABC")}</title>
      </Helmet>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "background.paper",
          pb: "100px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // overflow: "scroll",
            flexGrow: 1,
          }}
        >
          {/* <Box
            sx={{
              display: "flex",
              width: "100%",
            }}
          >
            <OnboardingIcon width="100%" height="100%" />
          </Box> */}
          <Box
            sx={{
              backgroundImage:
                "linear-gradient(135deg, #ffcd00, #ffbb00, #ffa900, #ff9600, #ff8300, #ff7600, #ff6800, #ff5900, #ff4c00, #ff3e00, #ff2a00, #ff0100)",
              display: "grid",
              justifyContent: "flex-end",
              alignItems: "end",
              minHeight: 400,
              paddingRight: "10%",
            }}
          >
            <Box
              component="img"
              src={Cindy}
              maxHeight="320px"
              sx={{ marginRight: "10%" }}
            />
            <ABCwalletLogo
              style={{
                position: "absolute",
                width: "10%",
                top: 0,
                right: 0,
                margin: "24px",
                minWidth: "90px",
              }}
            />
            <Typography
              sx={{
                position: "absolute",
                top: {
                  xs: "20%",
                  sm: "20%",
                  md: "25%",
                },
                left: "3%",
                color: "background.paper",
                fontSize: {
                  xs: "2em",
                  sm: "2.5em",
                  md: "3em",
                },
                fontWeight: 600,
                wordWrap: "break-word",
                width: {
                  xs: 200,
                  sm: 330,
                  md: 420,
                },
              }}
            >
              Easy Everyday Coin
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "0 16px",
            }}
          >
            <Box sx={{ m: "16px 0" }}>
              <Typography variant="h6">{t("welcome_title")}</Typography>
            </Box>
            <Typography variant="pharagraph" sx={{ marginBottom: "16px" }}>
              {t("first_paragraph")}
            </Typography>
            <Typography variant="pharagraph">
              {t("second_paragraph")}
            </Typography>
          </Box>
        </Box>
      </Box>
      <AppBar
        position="fixed"
        sx={{
          width: "100%",
          boxShadow: 2,
          top: "auto",
          bottom: 0,
          p: "16px 16px 32px",
          backgroundColor: "background.paper",
        }}
      >
        <Button
          id="terms-btn"
          variant="contained"
          fullWidth
          sx={{ height: 48, width: "100%", borderRadius: "24px" }}
          onClick={handleSignup}
        >
          <Typography variant="button" color="background.paper">
            {t("sign_up_btn")}
          </Typography>
        </Button>
      </AppBar>
    </>
  );
};

export default Onboarding;
