import React, { Suspense, StrictMode } from "react";
import ReactDOM from "react-dom";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { keycloak } from "./config";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import App from "./App";
import { HelmetProvider } from "react-helmet-async";
import CustomRouter from "./components/customRouter/CustomRouter";
import { Provider as ReduxProvider } from "react-redux";
import { AuthProvider } from "./contexts/authContext/AuthContext";
import { SettingsProvider } from "./contexts/SettingsContext";
import { StyledEngineProvider } from "@mui/material/styles";
import SplashScreen from "./components/splashScreen/SplashScreen";
import configureStore from "./redux/store/configureStore";
import "./clipboardConfig";
import { history } from "./history";
// import { mixpanel } from "./mixpanel/mixpanelConfig";
const store = configureStore();
// mixpanel.reset();

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: "https://62649b2c81bd476eb9763bb15903e452@o1280396.ingest.sentry.io/6483576",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

console.log("version", process.env.REACT_APP_VERSION);
Sentry.captureMessage("version debugTrueID");

ReactDOM.render(
  <StrictMode>
    <ReactKeycloakProvider
      authClient={keycloak}
      LoadingComponent={<SplashScreen />}
      initOptions={{ checkLoginIframe: false }}
    >
      <Suspense fallback={<SplashScreen />}>
        <HelmetProvider>
          <ReduxProvider store={store}>
            <StyledEngineProvider injectFirst>
              <SettingsProvider>
                <CustomRouter history={history}>
                  <AuthProvider>
                    <App />
                  </AuthProvider>
                </CustomRouter>
              </SettingsProvider>
            </StyledEngineProvider>
          </ReduxProvider>
        </HelmetProvider>
      </Suspense>
    </ReactKeycloakProvider>
  </StrictMode>,
  document.getElementById("root"),
);
