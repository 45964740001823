import React from "react";
import {
  Box,
  Button,
  IconButton,
  Modal,
  styled,
  Typography,
} from "@mui/material";
import { ReactComponent as ConvertIcon } from "../../../../../assets/icons/convert-button-icon.svg";
import TruePointLogo from "../../../../../assets/images/MyWallet/truepoint 1.png";
import Prompt from "../../../../../components/Prompt/Prompt";
import ABCBindingTruePointLogo from "../../../../../assets/images/MyWallet/abc_binding_truepoint.png";
import useTranslation from "../../../../../hooks/useTranslation";
import { apiEndpoint, authConfig } from "../../../../../config";
import queryString from "query-string";
import AuthContextService from "../../../../../contexts/authContext/AuthContextServices";
import { useLocation } from "react-router-dom";
import { ReactComponent as CloseIcon } from "../../../../../assets/icons/close.svg";

const Circle = styled(Box)`
  display: flex;
  justify-content: center;
  align-self: center;
  box-sizing: border-box;
  position: absolute;
  background-color: hsl(0deg 0% 100% / 70%);
  border-radius: 50%;
  overflow: hidden;
  height: 111px;
  right: -8px;
  padding: 12px;
  border-top: none;
  border-right: none;
  border-bottom: none;
`;
function dec2hex(dec) {
  return dec.toString(16).padStart(2, "0");
}

export function generateRandomString(len) {
  var arr = new Uint8Array((len || 40) / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join("");
}

const ConvertButton = () => {
  const [promptOpen, setPromptOpen] = React.useState(false);
  const [errorPromptOpen, setErrorPromptOpen] = React.useState(false);
  const location = useLocation();
  const lang = localStorage.getItem("lang");
  const [errorBindingMessage, setErrorBindingMessage] = React.useState("");
  const { t } = useTranslation("translation", {
    keyPrefix: "convertCoins",
  });

  const { t: loginTranslation } = useTranslation("translation", {
    keyPrefix: "LoginTrueIDPrompt",
  });

  const onClickConvert = () => {
    setPromptOpen(true);
  };

  const onPrompContinueClick = async () => {
    try {
      const randomString = generateRandomString(10);
      AuthContextService.setLocalStorageTrueRandomState(randomString);
      const url = queryString.stringifyUrl({
        url: apiEndpoint.authTrueId,
        query: {
          client_id: authConfig.trueClientId,
          redirect_uri: `${authConfig.trueAuthRedirectURI}?lang=${lang}`,
          state: randomString,
          lang,
        },
      });
      window.location.href = url;
    } catch (error) {
      setPromptOpen(false);
      setErrorBindingMessage(error + "");
      setErrorPromptOpen(true);
    }
  };

  const onCloseIconClick = () => {
    setPromptOpen(false);
  };

  const onErrorPromptCloseIconClose = () => {
    setErrorPromptOpen(false);
    setErrorBindingMessage("");
  };

  React.useEffect(() => {
    if (!location) return;
    const { search } = location;
    if (search) {
      const value = queryString.parse(search);
      if (value.openBinding === "true") {
        setPromptOpen(true);
      }
    }
  }, []);

  return (
    <Box
      sx={{
        mx: "20px",
        mt: "24px",
      }}
    >
      <Button
        data-testid="convert-loyalty-point-btn"
        onClick={onClickConvert}
        sx={{
          background:
            "radial-gradient(237.18% 563.07% at 20.99% 13.14%, #F42E51 0%, #FB7029 26.92%, #FA9723 74.2%)",
          width: "100%",
          borderRadius: "16px",
          display: "flex",
          px: "16px",
          py: "0px",
          justifyContent: "flex-start",
        }}
      >
        <Box
          sx={{
            padding: "12px",
            borderRadius: "50px",
            backgroundColor: "background.paper",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ConvertIcon />
        </Box>
        <Typography
          variant="subtitle"
          sx={{ ml: "8px", my: "25px", color: "grey.accent5" }}
        >
          {t("Convert loyalty  point")}
        </Typography>
        <Circle>
          <Box
            component="img"
            src={TruePointLogo}
            sx={{
              height: "75px",
              margin: "auto",
            }}
          />
        </Circle>
      </Button>
      <Prompt
        isVisible={errorPromptOpen}
        rawSubTitle={errorBindingMessage}
        icon={"info"}
        isCloseButtonVisible={true}
        titleSx={{ mb: "16px" }}
        subTitleSx={{ mb: "24px" }}
        onCloseIcon={onErrorPromptCloseIconClose}
        onClose={onErrorPromptCloseIconClose}
        title={"Binding Failed"}
        buttonText={"OK"}
        keyPrefix={"LoginTrueIDPrompt"}
      />

      <Modal
        open={promptOpen}
        onClose={onCloseIconClick}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            bgcolor: "background.paper",
            maxWidth: "340px",
            minHeight: "258px",
            m: "16px",
            justifyContent: "space-between",
            borderRadius: "16px",
            p: "24px",
            overflowY: "auto",
            maxHeight: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "end",
              flexDirection: "column",
            }}
          >
            <IconButton
              data-testid="btn-close-top-right"
              id="btn_xTopRight"
              onClick={onCloseIconClick}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            id="img_logoPopup"
            component="img"
            src={ABCBindingTruePointLogo}
            sx={{
              marginTop: "6px",
              marginBottom: "24px",
              alignSelf: "center",
              width: "226px",
            }}
          />
          <Typography
            id="lbl_titlePopup"
            variant="h6"
            sx={{
              mb: "16px",
            }}
            align="center"
          >
            {loginTranslation("A New Way To Use Your Points!")}
            <span style={{ display: "block" }}>
              {loginTranslation("2 A New Way To Use Your Points!")}
            </span>
          </Typography>
          <Typography
            id="lbl_contentPopup"
            variant="body2"
            sx={{
              mb: "24px",
            }}
            align="center"
          >
            {loginTranslation(
              "Easily transfer and exchange your point to ABC coin and get more benefits from ABC. Start by binding to your ABC account",
            )}
          </Typography>
          <Box
            sx={{
              width: "100%",
              mb: 1,
            }}
          >
            <Button
              id="btn_1Popup"
              data-testid="btn_1Popup"
              color="primary"
              variant="contained"
              sx={{ borderRadius: 16, height: "50px", width: "100%" }}
              onClick={onPrompContinueClick}
            >
              <Typography variant="button">
                {loginTranslation("BINDING NOW")}
              </Typography>
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default ConvertButton;
