import { AppBar, Avatar, Button, Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import useNavigationHook from "../../../../hooks/useNavigation";
import useTranslation from "../../../../hooks/useTranslation";
import Coins from "../../../../assets/icons/coins.svg";
import Wallet from "../../../../assets/icons/wallet.svg";
import { ReactComponent as ArrowDownIcon } from "../../../../assets/icons/arrow-down.svg";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useDispatch,
  useSelector,
} from "../../../../redux/store/configureStore";
import {
  fetchAsyncTransferCoin,
  resetTransferCoin,
  status as transferCoinStatus,
} from "../../redux/slices/transferCoin/TransferCoin";
import Prompt from "../../../../components/Prompt/Prompt";
import LoadingPrompt from "../../../../components/loadingPrompt/LoadingPrompt";
import { trackVisitTransferCoindReviewPage } from "../../../../mixpanel";

function close() {}

const TransferCoinReview = () => {
  const setActiveMenu = useNavigationHook();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [promptOpen, setPromptOpen] = useState(false);
  const [promptError, setPromptError] = useState({
    title: null,
    subtitle: null,
    rawTitle: null,
    rawSubTitle: null,
    buttonText: null,
    onClose: close,
  });
  const [promptLoadingOpen, setPromptLoadingOpen] = useState(false);
  const [promptLoading, setPromptLoading] = useState({
    title: null,
  });
  const transfer = useLocation().state?.transfer;
  const {
    amount,
    phoneNumber,
    // abcChainAccount,
    fromWalletAddress,
    userName,
  } = transfer;
  const transferCoin = useSelector(state => state.transferCoin);
  const { t } = useTranslation("translation", {
    keyPrefix: "transferCoin",
  });
  useEffect(() => {
    setActiveMenu(false);
    trackVisitTransferCoindReviewPage();
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetTransferCoin());
    };
  }, []);

  useEffect(() => {
    if (transferCoin.status === transferCoinStatus.LOADING) {
      setPromptLoading({ title: "Transaction processing..." });
      setPromptLoadingOpen(true);
      return;
    }
    if (transferCoin.status === transferCoinStatus.LOADED) {
      setPromptLoadingOpen(false);
      navigate("/transfer-complete", {
        state: {
          transferComplete: {
            amount: amount,
            date: transferCoin.transfer.headers.timeReceived,
            transactionId: transferCoin.transfer.transactionHash,
            fromWalletAddress,
            toHash: transferCoin.transfer.to,
            to: {
              userName,
              phoneNumber,
            },
          },
        },
      });
      return;
    }
    if (transferCoin.status === transferCoinStatus.ERROR) {
      if (
        transferCoin.errorMessage &&
        typeof transferCoin.errorMessage === "string"
      ) {
        setPromptLoadingOpen(false);
        setPromptError({
          title: null,
          subtitle: null,
          rawTitle: transferCoin.errorMessage,
          rawSubTitle: null,
          buttonText: "OK",
          onClose: () => setPromptOpen(false),
        });
        setPromptOpen(true);
        return;
      }
      if (
        transferCoin.errorMessage &&
        typeof transferCoin.errorMessage === "object"
      ) {
        const { error, statusCode, message } = transferCoin.errorMessage;
        setPromptLoadingOpen(false);
        if (statusCode === 500) {
          setPromptError({
            title: null,
            subtitle: null,
            rawTitle: message,
            rawSubTitle: null,
            buttonText: "OK",
            onClose: () => {
              navigate("/wallet");
            },
          });
          setPromptOpen(true);
          return;
        }
        if (error === "invalid_recipient_phone") {
          setPromptError({
            title: "Invalid mobile number",
            subtitle: null,
            rawTitle: error,
            rawSubTitle: null,
            buttonText: "OK",
            onClose: () => {
              navigate("/wallet");
            },
          });
          setPromptOpen(true);
          return;
        }
        setPromptError({
          title: null,
          subtitle: null,
          rawTitle: error,
          rawSubTitle: null,
          buttonText: "OK",
          onClose: () => {
            navigate("/wallet");
          },
        });
        setPromptOpen(true);
        return;
      }
      setPromptLoadingOpen(false);
      setPromptError({
        title: "Oops, there was an issue",
        subtitle: "Please try again",
        rawTitle: null,
        rawSubTitle: null,
        buttonText: "OK",
        onClose: () => setPromptOpen(false),
      });
      setPromptOpen(true);
    }
  }, [transferCoin.status]);

  const handleSendClick = () => {
    dispatch(
      fetchAsyncTransferCoin({
        amount: +amount,
        receiver: phoneNumber.toString().replaceAll("-", ""),
      }),
    );
  };

  return (
    <>
      <Helmet>
        <title>{t("Review")}</title>
      </Helmet>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: "32px 16px 0",
          backgroundColor: "background.paper",
          height: "calc(100vh - 50px)",
        }}
      >
        <Box
          sx={{
            border: "1px solid",
            borderColor: "grey.accent3",
            borderRadius: "16px",
            padding: "16px",
          }}
        >
          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
            {t("You send")}
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              gap: "9px",
              marginTop: "17px",
            }}
          >
            <Avatar
              id="img_abcLogo_ConvertLoyaltyPointPage"
              sx={{
                height: "40px",
                width: "40px",
              }}
              src={Coins}
            />
            <Box>
              <Typography variant="body1">ABC</Typography>
              <Typography
                variant="body1"
                sx={{ marginTop: "8px", marginBottom: "28px" }}
              >
                {amount} ABC
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              alignItems: "center",
              gap: "22px",
            }}
          >
            <ArrowDownIcon />
            <Divider />
          </Box>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 500,
              marginTop: "28px",
            }}
          >
            {t("To")}
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              gap: "9px",
              marginTop: "17px",
            }}
          >
            <Avatar
              sx={{
                height: "40px",
                width: "40px",
                padding: "8px",
                border: "1px solid",
                borderColor: "grey.accent3",
              }}
              src={Wallet}
            />
            <Box>
              <Typography variant="body1">{userName}</Typography>
              <Typography variant="body1" sx={{ marginTop: "8px" }}>
                {phoneNumber}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Typography
          variant="body2"
          sx={{
            marginTop: "16px",
            textAlign: "center",
            color: "grey.accent2",
          }}
        >
          {t("Transaction may take a few second.")}
        </Typography>
      </Box>
      <AppBar
        position="fixed"
        sx={{
          width: "100%",
          top: "auto",
          bottom: 0,
          p: "16px 16px 32px",
          backgroundColor: "background.paper",
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            padding: "0 8px",
            marginBottom: "16px",
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: 500, color: "grey.accent1" }}
          >
            {t("Total amount")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              gap: "4px",
            }}
          >
            <Avatar
              id="img_abcLogo_ConvertLoyaltyPointPage"
              sx={{
                height: "24px",
                width: "24px",
              }}
              src={Coins}
            />
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 500, color: "grey.accent1" }}
            >
              {amount} ABC
            </Typography>
          </Box>
        </Box>
        <Button
          data-testid="transfer_coin.confirm_button"
          color="primary"
          to="/transfer-review"
          variant="contained"
          sx={{ borderRadius: "24px", width: "100%", height: "48px" }}
          fullWidth
          onClick={handleSendClick}
        >
          <Typography variant="button">{t("Confirm")}</Typography>
        </Button>
      </AppBar>
      <LoadingPrompt
        isVisible={promptLoadingOpen}
        title={promptLoading.title}
        keyPrefix={"transferCoin"}
      />
      <Prompt
        isVisible={promptOpen}
        icon={"error"}
        onClose={promptError.onClose}
        title={promptError.title}
        subTitle={promptError.subtitle}
        rawTitle={promptError.rawTitle}
        rawSubTitle={promptError.rawSubTitle}
        buttonText={promptError.buttonText}
        keyPrefix={"transferCoin"}
      />
    </>
  );
};

export default TransferCoinReview;
