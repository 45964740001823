import Keycloak from 'keycloak-js';

export const authConfig = {
  clientId: process.env.REACT_APP_CLIENT_ID,
  trueClientId: process.env.REACT_APP_AUTH_CLIENT_ID,
  trueAuthRedirectURI: process.env.REACT_APP_AUTH_TRUE_ID_REDIRECT_URI
};

export const featureFlag = {
  enebleConvertLoyaltyPoint: process.env.REACT_APP_ENABLE_CONVERT_LOYALTY_POINT,
  enableNftMyCollection: process.env.REACT_APP_ENABLE_NFT_MY_COLLECTION === "true",
};

export const debugFlag = {
  enableDebugTool: process.env.REACT_APP_DEBUG_TOOL === "true",
};

export const apiEndpoint = {
  endpoint: process.env.REACT_APP_ENDPOINT,
  tos: process.env.REACT_APP_TNC_ENDPOINT,
  deals: `${process.env.REACT_APP_BASE_URL}/wallet-vouchers-service/v1`,
  merchants: `${process.env.REACT_APP_BASE_URL}/wallet-vouchers-service/v1`,
  coins: `${process.env.REACT_APP_BASE_URL}/abc-token`,
  vouchers: `${process.env.REACT_APP_BASE_URL}/wallet-vouchers-service/v1`,
  profile: process.env.REACT_APP_BASE_URL,
  partner: process.env.REACT_APP_BASE_URL,
  nfts: `${process.env.REACT_APP_BASE_URL}/wallet-mynfts-service/v1`,
  nftMyCollection: `${process.env.REACT_APP_BASE_URL}/abc-nft-service/v1`,
  rate: `${process.env.REACT_APP_POINT_EXCHANGE_ENDPOINT}/v1`,
  convert: `${process.env.REACT_APP_POINT_EXCHANGE_ENDPOINT}/v1`,
  trueId: `${process.env.REACT_APP_POINT_EXCHANGE_ENDPOINT}/v1`,
  transactions: `${process.env.REACT_APP_COIN_TRANSACTION_ENDPOINT}/v1`,
  transferCoin: `${process.env.REACT_APP_TRANSFER_COIN_ENDPOINT}`,
  authTrueId: process.env.REACT_APP_AUTH_TRUE_ID_URL
};

export const keycloak = new Keycloak({
  url: `${process.env.REACT_APP_AUTH_BASE_URL}`,
  realm: `${process.env.REACT_APP_KEYCLOAK_REALM}`,
  clientId: `${process.env.REACT_APP_CLIENT_ID}`,
});
