import React, { useEffect } from "react";
import { Typography, Button, Container, Box } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import useTranslation from "../../hooks/useTranslation";
import GenericErrorImg from "../../assets/images/GenericErrorImg.svg";
import useNavigationHook from "../../hooks/useNavigation";

const GenericError = props => {
  const {
    title,
    body,
    buttonText,
    navigateTo,
    redirect,
    status,
    keyPrefix = "genericError",
    isHideMenu,
    center,
  } = props;

  const { t } = useTranslation("translation", { keyPrefix });
  const setActiveMenu = useNavigationHook();

  useEffect(() => {
    isHideMenu && setActiveMenu(false);
  });

  return (
    <>
      <Box
        sx={{
          display: "grid",
          gridTemplateRows: "1fr auto",
          height: "calc(100vh - 64px)",
          // flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            p: "0 16px",
            // minHeight: center && "calc(100vh - 50px)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              pb: "30px",
            }}
          >
            <Box
              alt="Error img not found"
              component="img"
              src={GenericErrorImg}
              sx={{
                height: "60px",
                width: "60px",
              }}
            />
          </Box>
          <Typography
            variant="h6"
            align="center"
            color="textPrimary"
            sx={{ pb: "25px" }}
          >
            {t(title)}
          </Typography>
          <Typography
            variant="body1"
            align="center"
            color="textSecondary"
            sx={{ pb: "50px" }}
          >
            {t(body)}
            {+status ? ` (${status})` : ""}
          </Typography>
        </Box>
        {buttonText && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              // position: "fixed",
              // bottom: 0,
              p: "16px 16px 32px",
            }}
          >
            <Button
              color="primary"
              component={RouterLink}
              to={navigateTo}
              variant="contained"
              sx={{ borderRadius: "24px", width: "100%", height: "48px" }}
              onClick={redirect}
              id="genericError"
              fullWidth
            >
              <Typography variant="button">{t(buttonText)}</Typography>
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};

export default GenericError;
